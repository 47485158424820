import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import NavigationElements from '../components/pageElements/NavigationElements';
import FooterElement from '../components/pageElements/FooterElement';
import FormComboBox from '../common/components/formElements/FormComboBox';
import { personalDocuments, formattedCountryData } from '../common/utils/formattedData';
import imgLogin from './../images/new_login_screen_image.png';
import SuccessModal from '../common/components/customSuccessModal/CustomSuccessModal';
import ErrorModal from '../common/components/customRedAlert/CustomRedAlert';
// import InputMask from 'comigo-tech-react-input-mask';
import InputMask from 'react-input-mask';

import axios from 'axios';
import FormInputField from '../common/components/formElements/FormInputField';
import HalfRow from '../common/components/formElements/HalfRow';
import { Tooltip } from 'react-tooltip'
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormSelectDrop from '../common/components/formElements/FormSelectDrop';
import {
  LoginSocialApple,
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialLinkedin
} from 'reactjs-social-login'
import { jwtDecode } from "jwt-decode";
import FormTextArea from '../common/components/formElements/FormTextArea';



const date = new Date();
const MIN_AGE = 18;
const MAX_DATE = new Date(date.setFullYear(date.getFullYear() - MIN_AGE)).toJSON().slice(0, 10);
const defaultNationalityOpt = { id: 'default', label: 'Selecciona nacionalidad', value: 'Selecciona nacionalidad', disabled: true };
const API_SERVER = process.env.REACT_APP_API_SERVER;


export default function SignUpPage(props) {
  const { displayNavigation, onUpdateDisplayNavigation, userType = 'patient' } = props;
  const [documentTypes, setDocumentTypes] = useState(null);
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState('1');
  const [subList, setSubList] = useState([])
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false
  })
  const [showSubFields, setShowSubFields] = useState(false);



  const formSchema = (userType) => {
    const passwordRequirements = z
      .string()
      .min(8, 'Password must have more than 8 characters')
      .regex(/[A-Z]/, 'Password must have at least one uppercase letter')
      .regex(/[!@#$%^&*(),.?":{}|<>]/, 'Password must have at least one special character');

    const baseSchema = {
      firstName: z.string().min(1, 'First Name is required').max(100),
      lastName: z.string().min(1, 'Last Name is required').max(100),
      phone: userType === 'doctor' ? z.string().min(7, "Phone is required").max(8) : z.string(),
      address: z.string().min(1, "Address is required"),
      idDocumentTypes: z.string(),
      personalDocument: z.string(),
      birthDate: z.date(),
      countryCode: z.string(),
      email: z.string().email('Invalid email').min(1, 'Email is required'),
      password: passwordRequirements,
      passwordConf: z.string().min(1, 'Password confirmation is required'),
      subscription: userType === 'doctor' ? z.string().min(1, "Subscription is required") : z.string().optional(),
      agreement: z.literal("true", { errorMap: () => ({ message: 'You must accept the terms and conditions' }) }),
      subDescription: userType === 'doctor' ? z.string().min(1, 'Subscription description is required').max(100) : z.optional(),
      subValue: userType === 'doctor' ? z.string().min(1, 'Subscription value is required').regex(/^\$[0-9]+$/, 'Subscription value must be a number') : z.optional(),
    };

    const schema = z.object(baseSchema).superRefine((data, ctx) => {
      // Password match validation
      if (data.password !== data.passwordConf) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Passwords do not match',
          path: ['passwordConf'],
        });
      }

      // Document validation for doctors

      if (data.idDocumentTypes === "1" && data.personalDocument.length !== 10) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid document number',
          path: ['personalDocument'],
        });
      } else if (data.idDocumentTypes === "2" && data.personalDocument.length !== 9) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid document number',
          path: ['personalDocument'],
        });
      }

    });

    return schema;
  };



  const schema = formSchema(userType);

  // Now you can use `schema` to validate your form data




  const { register, handleSubmit, formState, watch, control, getValues, setValue, reset } = useForm({ resolver: zodResolver(schema) });
  const { errors, isSubmitting, isValid } = formState;

  const getDocumentTypes = async () => {
    await axios
      .get(`${API_SERVER}/admin-setup/document-types`)
      .then((res) => {
        // const defaultDocument = res?.data?.find((doc) => doc?.documentName === 'DUI');
        // defaultDocument &&
        //   setSelectedDocument({
        //     ...defaultDocument,
        //     label: personalDocuments[defaultDocument.documentName] ?? defaultDocument.documentName,
        //     value: personalDocuments[defaultDocument.documentName] ?? defaultDocument.documentName
        //   });
        setDocumentTypes(res?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const personalDocumentValidation = (value) => {
    if (selectedDocument.label === 'DUI') {
      return /^\d{8}-\d{1}$/i.test(value);
    }
    return true;
  };
  useEffect(() => {
    getDocumentTypes();
    console.log(isSubmitting, 'isSubmitting');
  }, []);

  const onSignUp = async (data) => {
    console.log(data)

    if (userType === 'patient')
      delete data.subscription

    if (selectedDocument) {
      const payload = { ...data, idDocumentTypes: Number(selectedDocument) };
      if (userType === 'doctor') {
        payload.nationality = 'El Salvador';
      }
      try {
        const endpoint = userType === 'patient' ? '/auth/signup' : '/doctor-data/signup';
        const response = await axios.post(`${API_SERVER}${endpoint}`, payload);
        setShowSuccessModal(true);
      } catch (error) {
        console.error('There was an error on user sign up', error);
        switch (error.message) {
          case 'EMAIL IN USE':
            setErrorBody(`Una cuenta con el correo electrónico ingresado ya existe, por favor intentar de nuevo con otro correo electrónico`);
            break;
          default:
            setErrorBody('Error al crear una cuenta, verifique los datos ingresados e intente nuevamente');
            break;
        }
        setShowErrorModal(true);
      }
    }
  };
  const onCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/sign-in');
  };
  const onCloseErrorModal = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };


  const profileTypeOptions = [
    { id: 'informational', label: 'Informational Only' },
    { id: 'withAppointment', label: 'Informational with Appointment' }
  ]



  const getSubList = async () => {
    try {
      const res = await axios.get(`${API_SERVER}/subscription/list`)
      let list = res?.data?.map((val) => ({ label: val?.title, value: (val?.title)?.toLowerCase(), description: val?.description, price: val?.price }))
      setSubList(list)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getSubList()
  }, [])

  const showAndHidePassword = (type) => {
    if (type == "password") {
      setShowPassword((prev) => ({ ...prev, password: !showPassword.password }))

    } else {
      setShowPassword((prev) => ({ ...prev, confirm_password: !showPassword.confirm_password }))
    }
  }

  const handleSubChange = (event) => {
    const selectedSubscription = subList.find(item => item.value === event.target.value);
    console.log(subList, selectedSubscription, getValues('subscription'), 'subscription');
    if (selectedSubscription) {
      setValue('subDescription', selectedSubscription.description);
      setValue('subValue', '$'+selectedSubscription.price);
      setShowSubFields(true);
    } else {
      setValue('subDescription', '');
      setValue('subValue', '');
      setShowSubFields(false);
    }
  }

  return (
    <NavigationElements displayNavigation={displayNavigation} onUpdateDisplayNavigation={onUpdateDisplayNavigation}>
      <div className="bg-dl-screen-lightblue p-20">
        {/* <div className='w-full pb-10 text-center'><Link to={'/'}> <img className='w-72 mx-auto' src={logo} alt='Workflow' /></Link></div> */}
        <div className="min-h-full grid grid-flow-col ">
          <div className="col-span-12 md:col-span-8">
            <form className=" space-y-6" onSubmit={handleSubmit(onSignUp)}>
              <div className="rounded-2xl overflow-hidden mt-0 mb-45">
                <div className="bg-white">
                  <div className="form-heading-top">
                    <h3>Datos generales</h3>
                  </div>
                  <div className="grid grid-cols-6 gap-6 pt-[20px] px-[40px] pb-[30px]">
                    <div className="col-span-6 sm:col-span-3">
                      <FormInputField identifier="firstName" label="Nombre(s)" type="string" registration={register('firstName')} errorMessage={errors.firstName?.message} />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <FormInputField identifier="lastName" label="Apellido(s)" type="string" registration={register('lastName')} errorMessage={errors.lastName?.message} />
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <FormInputField identifier="countryCode" label="Código de país" type="string" registration={register('countryCode')} errorMessage={errors.countryCode?.message} />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                      <FormInputField identifier="phone" label="Teléfono" type="string" registration={register('phone')} errorMessage={errors.phone?.message} />
                    </div>
                    <div className="col-span-6">
                      <FormInputField identifier="address" label="Domicilio" type="string" registration={register('address')} errorMessage={errors.address?.message} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-2xl overflow-hidden mt-0 mb-45">
                <div className="bg-white">
                  <div className="form-heading-top">
                    <h3>Documentación</h3>
                  </div>
                  <div className="grid grid-cols-6 gap-6 pt-[20px] px-[40px] pb-[30px]">
                    {documentTypes?.length > 0 && selectedDocument !== null ? (
                      <>
                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor={'idDocumentTypes'} className="block font-medium text-sm text-black">
                            Tipo de documento de identidad
                          </label>
                          <select
                            {...register('idDocumentTypes')}
                            onChange={(e) => {
                              setSelectedDocument(e.target.value);
                              reset({ personalDocument: '' });
                            }}
                            className="mt-1 block w-full bg-white border border-gray-300 rounded-full shadow-sm px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm font-normal	"
                          >
                            {documentTypes.map((item) => (
                              <option className="bg-white text-black" key={item.id} value={item.id}>
                                {item.documentName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* <div className="col-span-4 sm:col-span-2">
                          <Controller
                            control={control}
                            name="idDocumentTypes"
                            defaultValue={selectedDocument}
                            value={selectedDocument}
                            render={({ field }) => (
                              <FormComboBox
                                label="Tipo de documento de identidad"
                                data={documentTypes.map((doc) => ({ ...doc, label: personalDocuments[doc.documentName] ?? doc.documentName, value: personalDocuments[doc.documentName] ?? doc.documentName }))}
                                value={field.value}
                                onChange={(value) => {
                                  console.log(value);
                                  field.onChange(value);
                                  setSelectedDocument(value);
                                }}
                              />
                            )}
                          />
                        </div> */}
                        <div className="col-span-8 sm:col-span-4">
                          {/* <div className='flex flex-row gap-1.5'> */}
                          <label class="block text-sm font-medium text-gray-700">Numero de documento</label>
                          {/* <InputMask
                            identifier="personalDocument"
                            type="text"
                            name="personal-document"
                            id="personal-document"
                            autoComplete="family-name"
                            label="Numero de documento"
                            className="mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                            mask={selectedDocument === '1' ? '99999999-9' : '*********'}
                            {...register('personalDocument')}
                          /> */}
                          <InputMask
                            name="personal-document"
                            id="personal-document"
                            mask={selectedDocument === '1' ? '99999999-9' : '*********'}
                            maskChar={null}
                            className="mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                            {...register('personalDocument')}
                          />
                          {errors?.personalDocument ? <p className="mt-2 text-sm text-red-700">{errors?.personalDocument?.message}</p> : null}

                        </div>
                      </>
                    ) : null}
                    {/* </div> */}
                    {/* <FormComboBox label='Tipo de documento de identidad' data={[defaultNationalityOpt, ...formattedCountryData]} value={field.value} onChange={(value) => field.onChange(value)} />
                      <FormInputField identifier="idDocumentTypes" label="Tipo de documento de identidad" type="string" registration={register('idDocumentTypes')} errorMessage={errors.idDocumentTypes?.message} /> */}

                    {/* <div className="col-span-6 sm:col-span-2">
                      <FormInputField identifier="personalDocument" label="Numero de documento" type="string" registration={register('personalDocument')} errorMessage={errors.personalDocument?.message} />
                    </div> */}
                    <div className="col-span-6 signup_datepicker">
                      <label htmlFor="birthdate" className="block text-sm font-medium text-dl-accent">
                        Fecha de nacimiento
                      </label>
                      <Controller
                        control={control}
                        name="birthDate"

                        // registration={{...register('birthDate')}}
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => field.onChange(date)}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            maxDate={new Date(MAX_DATE)}
                            dateFormatCalendar="MMMM"
                            peekNextMonth
                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                            showFullMonthYearPicker={true}
                            placeholderText="DD/MM/AAAA"
                            className="mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                          />
                        )}
                      />
                      {errors.birthDate ? <p className="mt-2 text-sm text-red-700">{errors.birthDate?.message}</p> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-2xl overflow-hidden mt-0 mb-45">
                <div className="bg-white">
                  <div className="form-heading-top">
                    <h3>Cuenta</h3>
                  </div>
                  <div className="grid grid-cols-6 gap-6 pt-[20px] px-[40px] pb-[30px]">
                    <div className="col-span-6 ">
                      <FormInputField identifier="email" label="Correo" type="string" registration={register('email')} errorMessage={errors.email?.message} />
                    </div>
                    <div className="col-span-6 sm:col-span-3 ">
                      {/* <FormInputField identifier="password" label="Contraseña" type="password" registration={register('password')} errorMessage={errors.password?.message} /> */}
                      <div className="flex flex-row items-center mb-2 gap-2">
                        <label htmlFor={"password"} className=" text-sm font-medium text-dl-accent  text-sm text-black">
                          Contraseña
                        </label>

                        <span>


                          <a className="my-anchor-element">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                            </svg>
                          </a>
                          <Tooltip anchorSelect=".my-anchor-element" place="top" >
                            Password must have atleast 8 characters, <br /> 1 upper letter, and 1 special character.
                          </Tooltip>
                        </span>


                      </div>

                      <div className="relative rounded-md ">
                        <input
                          type={showPassword.password ? "text" : "password"}
                          name={"password"}
                          id={"password"}
                          className={` block w-full border border-gray-300 rounded-full  py-2 px-3 focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10	 `}
                          {...register('password')}
                        />
                        <span className='absolute top-2 right-3'>
                          {showPassword.password
                            ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => showAndHidePassword("password")} >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>

                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => showAndHidePassword("password")}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>
                          }
                        </span>

                      </div>
                      {errors.password?.message ? <p className="mt-2 text-sm text-red-700">{errors.password?.message}</p> : null}
                    </div>
                    {/* <div className="col-span-6 sm:col-span-3">
                      <FormInputField identifier="passwordConf" label="Confirmar contraseña" type="password" registration={register('passwordConf')} errorMessage={errors.passwordConf?.message} />
                    </div> */}

                    <div className="col-span-6 sm:col-span-3 ">
                      <label htmlFor={"passwordConf"} className="block text-sm font-medium text-dl-accent mb-2 text-sm text-black">
                        Contraseña
                      </label>

                      <div className="relative rounded-md ">
                        <input
                          type={showPassword.confirm_password ? "text" : "password"}
                          name={"passwordConf"}
                          id={"passwordConf"}
                          className={` block w-full border border-gray-300 rounded-full  py-2 px-3 focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10	 `}
                          {...register('passwordConf')}
                        />
                        <span className='absolute top-2 right-3'>
                          {showPassword.confirm_password
                            ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => showAndHidePassword("confirm_password")} >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>

                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => showAndHidePassword("confirm_password")}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>
                          }
                        </span>

                      </div>
                      {errors.passwordConf?.message ? <p className="mt-2 text-sm text-red-700">{errors.passwordConf?.message}</p> : null}
                    </div>

                    {userType === 'doctor' ?
                      <div className="col-span-6 sm:col-span-3">

                        <>
                          <div className='flex flex-row items-center mb-2 gap-2'>

                            <label htmlFor="subscription" className="block text-sm font-medium text-dl-accent text-sm text-black">Suscripción</label>
                            <a className="subscription">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                              </svg>
                            </a>
                            <Tooltip anchorSelect=".subscription" place="top" >
                              Lite : Sólo se permite visualización de perfil del doctor e información general <br />
                              Pro : Permite visualización del perfil e información general + el poder agendar citas, contar con un calendario y carga de documentos a los pacientes.
                            </Tooltip>
                          </div>
                          <select
                            id={"subscription"}
                            name={"subscription"}
                            onInput={(event) => handleSubChange(event)}
                            {...register('subscription', { required: true })}
                            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm"
                          >
                            <option key="empty-select-value" value="">
                              --- Seleccione --
                            </option>
                            {subList?.map((opt) => {
                              return (
                                <option key={opt.label} value={opt.value} >
                                  {opt.label}
                                </option>
                              );
                            })}
                          </select>

                          {errors.subscription ? <p className="mt-2 text-sm text-red-700">{errors.subscription?.message}</p> : null}
                        </>
                      </div>
                      : ""
                    }
                    {/* <div className="col-span-3"></div> */}
                    {
                      showSubFields
                      ? <>
                          <div className="col-span-3">
                            <FormTextArea identifier="subDescription" label="Descripción de suscripción" type="string" disabled={true} registration={register('subDescription')} errorMessage={errors.subDescription?.message} />
                          </div>
                          <div className="col-span-3">
                            <FormInputField identifier="subValue" label="Valor de suscripción" type="string" disabled={true} registration={register('subValue')} errorMessage={errors.subValue?.message} />
                          </div>
                        </>
                      : null
                    }
                  </div>
                </div>
              </div>

              <fieldset className="col-span-12 main-bottom-box">
                <div className="mt-4 space-y-4">
                  <div className="flex items-center justify-center">
                    <div className="h-5 flex items-center">
                      <input id="agreement" name="agreement" type="checkbox" value={true} className="focus:ring-dl-green h-4 w-4 text-dl-green border-gray-300 rounded" {...register('agreement')} />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="agreement" className="font-medium text-dl-accent">
                        Acepto &nbsp;
                        <a href="" className="text-dl-primary-800 hover:text-dl-primary-700 check-text font-bold">
                          Terminos y condiciones de DoctoLink
                        </a>
                      </label>
                    </div>
                  </div>
                  { errors.agreement 
                    ? <div class="flex items-center justify-center"><p className="text-red-700 font-light ">{errors.agreement?.message}</p></div> 
                    : null
                  }
                </div>
              </fieldset>
              <div className="btn-form mt-5">
                <button type="submit" className="btn-primary btn-md btn-sm  m-auto select-none" disabled={isSubmitting}>
                  Crear usuario
                </button>
              </div>

              {/* <p className='text-center'>or</p>
              <br /> */}
              {/* <div className='flex justify-center gap-6' >
                <LoginSocialGoogle
                  scope="openid profile email"
                  client_id={process.env.REACT_APP_GG_APP_ID || ''}
                  onResolve={({ provider, data }) => {
                    setValue("email", data?.email)
                    setValue("firstName", data?.given_name)
                    setValue("lastName", data?.family_name)
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                >
                  <img src='/defaultIcons/google.png' className='h-[30px] cursor-pointer' />
                </LoginSocialGoogle>


                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FB_APP_ID}
                  fieldsProfile={"id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"}
                  onResolve={({ provider, data }) => {
                    setValue("email", data?.email)
                    setValue("firstName", data?.first_name)
                    setValue("lastName", data?.last_name)
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                >
                  <img src='/defaultIcons/facebook.png' className='h-[30px] cursor-pointer' />
                </LoginSocialFacebook>
                <LoginSocialApple
                  client_id={process.env.REACT_APP_APPLE_ID}
                  redirect_uri={window.location.href || ''}
                  scope="email name"
                  onResolve={({ provider, data }) => {
                    const result = jwtDecode(data.authorization.id_token);
                    setValue("email", result?.email);
                    setValue("firstName", data?.name?.firstName)
                    setValue("lastName", data?.name?.lastName)
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                >
                  <img src='/defaultIcons/apple.png' className='h-[30px] cursor-pointer' />
                </LoginSocialApple>
              </div> */}
            </form>
          </div>
        </div>
      </div>

      {/* popup modal */}
      {/* Revisa tu correo electrónico para verificar tu cuenta. */}
      <SuccessModal
        title="Cuenta creada exitosamente"
        // bodyText="Se ha enviado un correo electrónico para confirmar su cuenta"
        bodyText="Se ha enviado un correo electrónico para confirmar su cuenta. Haga clic en el enlace de su correo electrónico para verificar su cuenta."
        buttonText="Finalizar"
        buttonAction={() => navigate(`/sign-in${userType === 'doctor' ? '-doctor' : ''}`)}
        open={showSuccessModal}
        onClose={onCloseSuccessModal}
      />
      <ErrorModal title="Error al crear cuenta" bodyText={errorBody} mainActionText="Cerrar" onMainAction={onCloseErrorModal} open={showErrorModal} onClose={onCloseErrorModal} />
      {/* Footer */}
      <FooterElement />
    </NavigationElements>
  );
}

