import React from 'react';
import { CxCard, CxWrap, Line } from '../../components/Sx';
import FormInputField from '../../../common/components/formElements/FormInputField';
import FormTextArea from '../../../common/components/formElements/FormTextArea';
import { useLocation, useNavigate } from 'react-router-dom';
import useAppointmentDetails from './hooks/useAppointmentDetails';
import ReactDatePicker from 'react-datepicker';
import { formatDateToTimeString, mergeDateTime } from '../../../common/helpers/helper';
import ProfileFormHeader from '../../../patientAppointmentManager/components/ProfileFormHeader/ProfileFormHeader';
import CustomRedAlert from '../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../common/components/customSuccessModal/CustomSuccessModal';


const AppointmentDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { patient, type } = state;

  const { handleSubmit, register, errors, getValues, setValue, availableAppointmentsDate, handleMonthChange, handleDateChange, 
    availableTime, onSaveSubmit, loading, watch, statusOptions, blockedDates,successModalOpen, showErrorModal, successMessage, errorBody, closeErrorMessage, onCloseSuccessMessage } = useAppointmentDetails(patient, type);

  return (
    <div className="bg-dl-screen-lightblue p-20">
      <div className="min-h-full grid grid-flow-col ">
        <div className="col-span-12 md:col-span-8">
          <form className="space-y-6" onSubmit={handleSubmit(onSaveSubmit)}>
            {/* <div className="shadow sm:rounded-md sm:overflow-hidden"></div> */}
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              {type === 'viewOnly' && <ProfileFormHeader title={'Detalles de la cita'} textColor={'text-blue-800'} />}
              {type === 'complete' && <ProfileFormHeader title={'Completar Cita'} textColor={'text-blue-800'} />}
              {type === 'reschedule' && <ProfileFormHeader title={'Solicitar reprogramación de cita'} textColor={'text-blue-800'} />}

              <CxWrap>
                <CxCard>Datos del paciente</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="form-label-box">
                      <FormInputField identifier="userFullName" label="Numbre del paciente" type="string" registration={register('userFullName')} disabled />
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="birthDate" label="Fecha de nacimmiento" type="string" registration={register('birthDate')} disabled />
                    </div>
                    <div className="form-label-box">
                      <label htmlFor={"Fecha de cita"} className="block text-sm font-medium text-dl-accent mb-2 text-sm text-black">
                        Fecha de cita
                      </label>
                      <ReactDatePicker
                        selected={getValues()?.appDate ? new Date(getValues()?.appDate) : new Date()}
                        onChange={date => {
                          handleDateChange(date)

                        }}
                        disabled={type !== 'reschedule'}
                        includeDates={availableAppointmentsDate}
                        // excludeDates={blockedDates}
                        minDate={new Date()}
                        onMonthChange={handleMonthChange}
                        className={` block w-full border border-gray-300 rounded-full  py-2 px-3 focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10	`}
                      />
                      {errors?.appDate ? <p className="mt-2 text-sm text-red-700">{errors?.appDate?.message}</p> : null}
                    </div>
                    <div className="form-label-box">
                      <label htmlFor={"Fecha de cita"} className="block text-sm font-medium text-dl-accent mb-2 text-sm text-black">
                        Hora de cita
                      </label>
                      <ReactDatePicker
                        selected={(getValues()?.appDate && getValues()?.appTime) ? mergeDateTime(getValues()?.appDate, getValues()?.appTime) : null}
                        onChange={(date) => {
                          setValue('appTime', formatDateToTimeString(date), { shouldValidate: true })
                        }}
                        disabled={type !== 'reschedule'}
                        includeTimes={availableTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"

                        dateFormat="h:mm aa"
                        className={` block w-full border border-gray-300 rounded-full  py-2 px-3 focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10	`}
                        onKeyDown={(e) => e.preventDefault()} 
                      />
                      {errors?.appTime ? <p className="mt-2 text-sm text-red-700">{errors?.appTime?.message}</p> : null}
                    </div>


                    <div className="form-label-box">
                      <FormInputField identifier="userPhone" label="Telefona" type="string" registration={register('userPhone')} disabled />
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="userEmail" label="Correo electronico" type="string" registration={register('userEmail')} disabled />
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="Sexo" label="Sexo" type="string" registration={register('sex')} disabled />
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="Tipo de Sangre" label="Tipo de Sangre" type="string" registration={register('blood_type')} disabled />
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            <div className="rounded-2xl overflow-hidden mt-0 mb-45">
              <CxWrap>
                <CxCard>Contacto</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-start-1 col-end-3 ">
                      <FormInputField identifier="Motivo de ls consulta" label="Motivo de ls consulta" type="string" registration={register('motiveName')} disabled />
                    </div>
                    <div className="col-start-1 col-end-7 ">
                      <FormInputField identifier="Notas del paciente" label="Notas del paciente" type="string" registration={register('userNotes')} disabled />
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            <div className="rounded-2xl overflow-hidden mt-0 mb-45">
              <CxWrap>
                <CxCard>Notas del doctor</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-start-1 col-end-7 ">
                      <FormTextArea label={'Notas de la cita'} identifier={'doctorNotes'} rows={2} registration={register('doctorNotes', { required: true })} disabled={type !== "reschedule"} />
                      {errors.doctorNotes ? <p className="mt-2 text-sm text-red-700 text-normal">Este campo no puede ser vacío</p> : null}
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            {/* {type === 'reschedule' ? (
              <div className="rounded-2xl overflow-hidden mt-0 mb-45">
                <CxWrap>
                  <CxCard>Mensaje para el paciente</CxCard>
                  <Line />
                  <CxCard>
                    <div className="grid grid-cols-6 gap-4">
                      <div className="col-start-1 col-end-7 ">
                        <FormTextArea identifier="Notas de la cita" label="Motivo de cancelación" type="string" registration={register('cancelInstructions')} />
                        {errors.cancelInstructions ? <p className="mt-2 text-sm text-red-700 text-normal">Este campo no puede ser vacío</p> : null}
                        </div>
                    </div>
                  </CxCard>
                </CxWrap>
              </div>
            ) : null} */}
            <div className="rounded-2xl overflow-hidden mt-0 mb-45">
              <CxWrap>
                <CxCard>Estado actual de la cita</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-start-1 col-end-7 ">
                      <label htmlFor="idAppointmentStatus" className=" text-sm font-medium text-gray-700">
                        Estado actual de cita
                      </label>
                      <select
                        id="idAppointmentStatus"
                        name="idAppointmentStatus"
                        value={watch('idAppointmentStatus')}
                        {...register('idAppointmentStatus', { required: true })}
                        className="mt-1 block w-full bg-white border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 font-normal focus:border-dl-primary-500 sm:text-sm"
                        disabled={type !== 'reschedule'}
                      >
                        <option key="empty-select-value" value="">
                          --- Seleccione --
                        </option>
                        {statusOptions &&
                          statusOptions.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.label}
                            </option>
                          ))}
                      </select>
                      {errors.idAppointmentStatus && (
                        <p className="mt-2 text-sm text-red-700 text-normal">Este campo es requerido</p>
                      )}
                      {/* <FormInputField identifier="idAppointmentStatus" label="Pendiente" type="string" registration={register('idAppointmentStatus')} disabled={type !=="reschedule"} /> */}
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            <div className="text-right">
              <button
                className="bg-white border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                onClick={() => navigate(-1)}
              >
                Regresar
              </button>
              {type === 'reschedule' ? (
                <button type="submit"
                  disabled={loading} 
                  className="ml-2 bg-red-400 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700">
                  Reprogramar cita
                </button>
              ) : null}
              {type === 'add' ? (
                <button className="ml-2 btn-primary !w-auto border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700">
                  Actualizar cita
                </button>
              ) : null}
            </div>

            {successModalOpen && <CustomSuccessModal buttonText={"Cerca"} title="" bodyText={successMessage ? successMessage : "Registro actualizado con éxito"} buttonAction={onCloseSuccessMessage} open={successModalOpen} />}
            {showErrorModal && <CustomRedAlert open={showErrorModal} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetail;
