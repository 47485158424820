import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import saveCompleteAppointment from '../api/saveCompleteAppointment';
import saveRescheduleAppointment from '../api/saveRescheduleAppointment';
import Axios from '../../../../../../v2/services/Axios';

const statusNames = {
    pending: 'Pendiente',
    completed: 'Completada',
    canceled_by_doctor: 'Cancelada por doctor',
    canceled_by_user: 'Cancelada por usuario'
};

export default function useAppointmentDetails(appointment, onSuccessfulSave, selectionMode, onCancel) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');


    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        getValues,
        // control,
        reset,
        setValue
    } = useForm();

    const statusOptions = [
        { value: 1, label: 'Pendiente' },
        { value: 2, label: 'Completada' },
        { value: 4, label: 'Cancelada' }
    ];

    useEffect(() => {
        console.log(appointment, 'appointment');
        reset({ ...appointment, statusName: statusNames[appointment.statusName] });
    }, [appointment, reset]);

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (successMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(successMessage);
    };

    const onCloseSuccessMessage = () => {
        onCancel()
        setShowSuccessModal(false);
        setSuccessBody('');
        onSuccessfulSave();

    };

    const onSaveComplete = async (data) => {
        if (data.doctorNotes && data.doctorNotes !== '') {
            const executeSaveComplete = await saveCompleteAppointment({
                idAppointment: data.apptID,
                doctorNotes: data.doctorNotes
            });

            if (executeSaveComplete) {
                _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
            }
        }
    };

    const onSaveReschedule = async (data) => {
        if (data.doctorNotes && data.doctorNotes !== '') {
            const executeSaveComplete = await saveRescheduleAppointment({
                idAppointment: data.apptID,
                cancelInstructions: data.doctorNotes
            });

            if (executeSaveComplete) {
                _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
            }
        }
    };

    const changeStatus = async (data) => {
        try {
            const payload = {
                idAppointment: data?.apptID,
                appointmentStatus: data?.idAppointmentStatus
            }
            let apiUrl
            if (payload?.appointmentStatus == '3') {
                return
            }

            if (payload?.appointmentStatus == '2') {
                apiUrl = "/appointment-doctor/complete-appointment"
            }
            else if (payload?.appointmentStatus == '1') {
                apiUrl = "/appointment-doctor/pending-appointment"
            }
            else if (payload?.appointmentStatus == '4') {
                apiUrl = "/appointment-doctor/cancel-appointment"
            }

            const res = await Axios.patch(apiUrl, payload);
            setShowSuccessModal(true)
            // const res = await 
        } catch (error) {
            setShowErrorModal(true)
            console.log(error)
        }
    }

    const onSaveSubmit = (data) => {
        changeStatus(data)
        // if (selectionMode === 'complete') {
        //     onSaveComplete(data);
        //     return;
        // }

        // if (selectionMode === 'reschedule') {
        //     onSaveReschedule(data);
        //     return;
        // }

        // _createErrorMessage('No fue posible enviar los datos. Por favor inténtelo de nuevo.');


    };





    return {
        register,
        handleSubmit,
        errors,
        getValues,

        watch,
        reset,
        setValue,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveComplete,
        onSaveReschedule,
        onSaveSubmit,
        statusOptions
    };
}
