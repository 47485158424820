import getAppointmentHistory from '../../pendingAppointments/api/getAppointmentHistory';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';

const statusNames = {
    pending: 'Pendiente',
    completed: 'Completada',
    canceled_by_doctor: 'Cancelada por doctor',
    canceled_by_user: 'Cancelada por usuario'
};

export default function useAppointmentHistory() {
    const [pendingAppointments, setPendingAppointments] = useState([]);
    const [completedAppointments, setCompletedAppointments] = useState([]);
    const [cancelledAppointments, setCancelledAppointments] = useState([])

    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectionMode, setSelectionMode] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [refresh, setRefresh] = useState(false)

    const navigate = useNavigate();
    const queryAppointmentHistory = async () => {
        const getHistory = await getAppointmentHistory(setErrorMessage);

        if (getHistory && getHistory.pending) {
            setPendingAppointments(getHistory.pending);
            setCompletedAppointments(getHistory.completed);
            setCancelledAppointments(getHistory?.cancelled || []);
            console.log(pendingAppointments, 'pendingAppointments');
            setIsLoading(false);
            return;
        }

        setIsLoading(false);

        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };

    useEffect(() => {
        queryAppointmentHistory();
    }, []);

    const onSuccessfulSave = () => {
        queryAppointmentHistory()
    }

    const _handleClick = (event, selectedItem) => {
        event.preventDefault();
        setSelectedAppointment(selectedItem);
    };

    const _closeAppointmentView = () => {
        console.log("inside")
        setSelectedAppointment({});
        setSelectionMode(null);
    };

    const completedTable = useMemo(() => {
        return completedAppointments.map((appointment) => {
            return {
                col1: appointment.userFullName,
                col2: appointment.userEmail,
                col3: appointment.motiveName,
                col4: appointment.appDate,
                col5: appointment.appTime,
                col6: statusNames[appointment.statusName],
                col7: (
                    <>
                        <a
                            href="#"
                            onClick={(e) => _handleClick(e, appointment)}
                            className="text-dl-primary-900 hover:text-dl-accent"
                        >
                            Ver Detalles
                            <span className="sr-only">, {appointment.userFullName}</span>
                        </a>
                        <br />
                        {/* <Link to={`/my-documents/${appointment.idUser}`}>agregar documento</Link> */}
                        <a className="text-dl-primary-900 hover:text-dl-accent cursor-pointer" onClick={() => navigate(`/my-documents/${appointment.idUser}/${appointment.userFullName}`, { replace: true })}>agregar documento</a>
                    </>
                )
            };
        });
    }, [completedAppointments]);

    const cancelledTable = useMemo(() => {
        return cancelledAppointments.map((appointment) => {
            return {
                col1: appointment.userFullName,
                col2: appointment.userEmail,
                col3: appointment.motiveName,
                col4: appointment.appDate,
                col5: appointment.appTime,
                col6: statusNames[appointment.statusName],
                col7: (
                    <>
                        <a
                            href="#"
                            onClick={(e) => _handleClick(e, appointment)}
                            className="text-dl-primary-900 hover:text-dl-accent"
                        >
                            Ver Detalles
                            <span className="sr-only">, {appointment.userFullName}</span>
                        </a>
                        <br />
                        {/* <Link to={`/my-documents/${appointment.idUser}`}>agregar documento</Link> */}
                        <a className="text-dl-primary-900 hover:text-dl-accent cursor-pointer" onClick={() => navigate(`/my-documents/${appointment.idUser}/${appointment.userFullName}`, { replace: true })}>agregar documento</a>
                    </>
                )
            };
        });
    }, [cancelledAppointments]);

    const pendingTable = useMemo(() => {
        return pendingAppointments.map((appointment) => {
            return {
                col1: appointment.userFullName,
                col2: appointment.userEmail,
                col3: appointment.motiveName,
                col4: appointment.appDate,
                col5: appointment.appTime,
                col6: statusNames[appointment.statusName],
                col7: (
                    <>
                        <a
                            href="#"
                            onClick={(e) => _handleClick(e, appointment)}
                            className="text-dl-primary-900 hover:text-dl-accent align-middle"
                        >
                            Ver Detalles
                            <span className="sr-only">, {appointment.userFullName}</span>
                        </a>
                        <br />
                        <a className="text-dl-primary-900 hover:text-dl-accent cursor-pointer" onClick={() => {

                            navigate(`/my-documents/${appointment.idUser}/${appointment.userFullName}`,
                                {
                                    replace: true,
                                    state: { apptID: appointment.apptID }
                                }

                            )
                        }}>agregar documento</a>
                    </>
                )
            };
        });
    }, [pendingAppointments]);

    const appointmentColumns = useMemo(() => {
        return [
            {
                Header: 'Nombre',
                accessor: 'col1' // accessor is the "key" in the data
            },
            {
                Header: 'Correo',
                accessor: 'col2' // accessor is the "key" in the data
            },
            {
                Header: 'Motivo',
                accessor: 'col3' // accessor is the "key" in the data
            },
            {
                Header: 'Fecha',
                accessor: 'col4' // accessor is the "key" in the data
            },
            {
                Header: 'Hora',
                accessor: 'col5' // accessor is the "key" in the data
            },
            {
                Header: 'Estado',
                accessor: 'col6' // accessor is the "key" in the data
            },
            {
                Header: 'Acciones',
                accessor: 'col7' // accessor is the "key" in the data
            }
        ];
    }, []);

    const completedTableInstance = useTable(
        { columns: appointmentColumns, data: completedTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );
    const cancelledTableInstance = useTable(
        { columns: appointmentColumns, data: cancelledTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );
    const pendingTableInstance = useTable(
        { columns: appointmentColumns, data: pendingTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );

    return {
        pendingAppointments,
        completedAppointments,
        errorMessage,
        queryAppointmentHistory,
        completedTable,
        pendingTable,
        isLoading,
        setIsLoading,
        appointmentColumns,
        completedTableInstance,
        pendingTableInstance,
        selectionMode,
        setSelectionMode,
        _closeAppointmentView,
        selectedAppointment,
        onSuccessfulSave, 
        cancelledTableInstance
    };
}
