import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import saveCompleteAppointment from '../api/saveCompleteAppointment';
import saveRescheduleAppointment from '../api/saveRescheduleAppointment';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import getDoctorAvailableDate, { getAvailableDate } from '../../../../../../selectAppointmentDate/api/getDoctorAvailableDate';
import { addMinutes, formatDate, mergeDateTime } from '../../../../../../common/helpers/helper';
import moment from 'moment';
import Axios from '../../../../../../v2/services/Axios';


export default function useAppointmentDetails(appointment, onSuccessfulSave, selectionMode) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');
  const [successBody, setSuccessBody] = useState('');
  const navigate = useNavigate();
  const [availableAppointmentsDate, setAvailableAppointmentsDate] = useState([])
  const [availableTime, setAvailableTime] = useState([])
  const [allAvailableAppointments, setAllAvailableAppointments] = useState([])
  const [error, setError] = useState("")
  const [monthData, setMonthData] = useState("")
  const [successModalOpen, setSuccessOpenModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [showUpdateCalendarBtn, setShowUpdateCalendarBtn] = useState(false)

  const schema = z.object({
    appointmentID: z.number(),
    appDate: z.string().nonempty('Se requiere fecha de cita'),
    appTime: z.string().nonempty('Se requiere hora de cita'),
    // doctorNotes: z.string().nonempty('Se requieren notas del médico'),
    userNotes: z.string().nonempty('Se requieren notas del médico'),
    idAppointmentStatus: z.string().nonempty('Se requieren notas del médico')
    // review: selectionMode === 'edit' ? z.string().optional() : z.string().max(100),
  });

const statusOptions = [
  { value: "1", label: 'Pendiente' },
  { value: "2", label: 'Completada' },
  { value: "3", label: 'Cancelada' }
];


  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    // control,
    reset,
    setValue,
    getValues
  } = useForm({
    resolver: zodResolver(schema),
  });


  useEffect(() => {
    reset({ ...appointment, idAppointmentStatus: appointment?.idAppointmentStatus?.toString() });

  }, [appointment, reset]);

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
  };

  const closeErrorMessage = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };

  const _createSuccessMessage = (successMessage) => {
    setShowSuccessModal(true);
    setSuccessBody(successMessage);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
    // onSuccessfulSave();
    navigate("/appointment-manager")
  };

  const onSaveComplete = async (data) => {
    if (data.doctorNotes && data.doctorNotes !== '') {
      const executeSaveComplete = await saveCompleteAppointment({
        idAppointment: data.apptID,
        doctorNotes: data.doctorNotes
      });

      if (executeSaveComplete) {
        _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
      }
    }
  };


  const getTimeId = (appointmentData, newData) => {
    let data = allAvailableAppointments?.find((appointData) => {
      if (appointData?.date == newData?.appDate && appointData?.appTime == newData?.appTime) {
        return appointData
      }
    })
    return { id: data?.id, idSchedule: data?.idSchedule }
    // return {id : data?.id , idSchedule : data?.idSchedule}

  }

  const onSaveReschedule = async (data) => {

    if (data) {
      const payload = {
        idAppointment: data?.appointmentID,
        idAvailability: getTimeId(appointment, data)?.id,
        idSchedule: getTimeId(appointment, data)?.idSchedule,
        idDoctor: appointment?.idDoctorUser,
        appointmentDate: data?.appDate,
        appTime: data?.appTime,
        doctorNotes: appointment?.doctorNotes,
        eventId: appointment?.eventId,
        userNotes: data?.userNotes,
        idAppointmentStatus: +data?.idAppointmentStatus
      };

      try {
        setLoading(true)
        const res = await Axios.post("appointment-user/update-appointment", payload);
        if(appointment?.eventId){
          updateEvent(data)
        }
        setSuccessOpenModal(true)
        setShowUpdateCalendarBtn(true)
        setSuccessMessage(res?.data?.message)
      } catch (error) {
        setShowErrorModal(true)
        console.log(error)
      } finally {
        setLoading(false)
      }


      // if (execu  teSaveComplete) {
      //   _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
      // }
    }
  };

  const updateEvent = async (data) => {
    try {

      const payload = {
        title: `Appointment with Dr ${appointment?.doctorFullName}`,
        start_date: mergeDateTime(data?.appDate, data?.appTime),
        end_date: addMinutes(mergeDateTime(data?.appDate, data?.appTime), 30),
        idAppointment: appointment?.appointmentID,
        eventId: appointment?.eventId
      }

      const res = await Axios.post("auth/update-google-event", payload)
      // setAppointmentAdded(true)
    } catch (error) {
      console.log("error ", error)
    }


  }

  const onSaveEdit = (data) => {
    console.log(data, 'data');
  }

  const onSaveSubmit = (data) => {
    if (selectionMode === 'complete') {
      onSaveComplete(data);
      return;
    }

    if (selectionMode === 'reschedule') {
      onSaveReschedule(data);
      return;
    }

    if (selectionMode === 'edit') {
      onSaveEdit(data);
      return;
    }

    _createErrorMessage('No fue posible enviar los datos. Por favor inténtelo de nuevo.');
  };


  const fetchMonthAppointments = async (startDate, endDate, appointmentDate) => {
    try {
      const dates = await getAvailableDate({
        doctorId: appointment.idDoctorUser,
        idDoctorProfile: appointment.idDoctorProfile,
        // startDate: startDate,
        // endDate: endDate,
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: '2025-12-30',
        setError
      });

      if (dates) {
        setAllAvailableAppointments(dates.availableTimesResponse)
        const availDates = [...new Set(dates.availableTimesResponse.map(d => d.date))]
        let filteredDated = []
        availDates?.map((item) => {
          if (!dates.blockedSlots.some(d => d.date == item)) {
            filteredDated?.push(new Date(item))
          }
        })
        setMonthData(new Date(endDate))
        setAvailableAppointmentsDate(filteredDated);

        if (appointmentDate) {
          getAvaiableTime(dates.availableTimesResponse, new Date(appointmentDate))
        }

      }
    } catch (error) {
      console.log(error)
    }

  }



  const handleMonthChange = (date) => {
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);

    fetchMonthAppointments(startDate, endDate);
  };

  const getAvaiableTime = (allAvailableAppointments, selectedDate) => {
    const selectedDateTime = []
    allAvailableAppointments?.map((item) => {
      if (moment(selectedDate).format("YYYY-MM-DD") == item?.date) {
        selectedDateTime.push(mergeDateTime(item?.date, item?.appTime))
      }
    })
    setAvailableTime(selectedDateTime)
  }

  useEffect(() => {
    if (appointment.appDate) {

      const format = "YYYY-MM-DD";
      const parsedDate = moment(appointment.appDate, format);

      if (!parsedDate.isValid()) {
        console.error('Invalid date format:', appointment.appDate);
        return;
      }
      const startDate = parsedDate.startOf('month').format('YYYY-MM-DD');
      const endDate = parsedDate.endOf('month').format('YYYY-MM-DD');
      // Log the start and end dates for verification

      fetchMonthAppointments(startDate, endDate, appointment.appDate);
    }
  }, [appointment.appDate]);

  const handleDateChange = (selectedDate) => {
    getAvaiableTime(allAvailableAppointments, selectedDate)
    setValue('appDate', format(selectedDate, 'yyyy-MM-dd'), { shouldValidate: true })
    setValue('appTime', "")
  }




  return {
    register,
    handleSubmit,
    errors,
    watch,
    reset,
    getValues,
    setValue,
    isDirty,
    closeErrorMessage,
    onCloseSuccessMessage,
    showSuccessModal,
    showErrorModal,
    errorBody,
    successBody,
    onSaveComplete,
    onSaveReschedule,
    onSaveSubmit,
    appointmentData: appointment,
    availableAppointmentsDate,
    allAvailableAppointments,
    handleMonthChange,
    monthData,
    handleDateChange,
    availableTime,
    successModalOpen, successMessage, loading, showUpdateCalendarBtn,
    statusOptions
  };
}
