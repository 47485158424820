import './App.css';

import { useEffect, useState } from 'react';
import es from 'date-fns/locale/es';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import DoctorProfilePage from './components/DoctorProfilePage';
import SignUpPage from './signUp/SignUpPage';
import ResultsPage2 from './components/ResultsPage';
import DoctorResultsPage2 from './components/DoctorResultsPage';
import DoctorDetailsPage from './components/DoctorDetailsPage';
import AppointmentCreationPage from './components/AppointmentCreationPage';
import AppointmentConfirmationPage from './components/AppointmentConfirmationPage';
import PatientProfilePage from './components/PatientProfilePage';
import DoctorsReviewPage from './components/DoctorsReviewPage';
import AboutUsPage from './components/AboutUsPage';
import ContactUsPage from './components/ContactUsPage';
import DoctorAppointmenDateSelectionPage from './components/DoctorAppointmenDateSelectionPage';
import Home from './home/Home';
import WhoWeAre from './whoWeAre/WhoWeAre';
import WhatIsDoctolink from './whatIsDoctolink/WhatIsDoctolink';
import SignIn from './auth/SignIn';
import useAuthentication from './api/hooks/useAuthentication';
import SignInDoctor from './auth/SignInDoctor';
import DoctorUserProfile from './v2/pages/DoctorProfile/DoctorUserProfile';

import ConfirmAccountPatient from './confirmAccount/ConfirmAccountPatient';
import ConfirmAccountDoctor from './confirmAccount/ConfirmAccountDoctor';
import ForgotPassword from './auth/forgotPassword/ForgotPassword';
import ForgotPasswordDoctor from './auth/forgotPassword/ForgotPasswordDoctor';
import ResetPassword from './auth/resetPassword/ResetPassword';
import ResetPasswordDoctor from './auth/resetPassword/ResetPasswordDoctor';
import CleanSession from './common/helpers/cleanSession/CleanSession';

import 'react-datepicker/dist/react-datepicker.min.css';
import SideBySideImage from './components/SideBySideImage';
import SearchResults from './searchResults/SearchResults';
import DoctorResultsTabsElement from './doctorResults/components/doctorResultsTabsElement/DoctorResultsTabsElement';
import SelectAppointment from './selectAppointmentMotive/components/selectAppointment/SelectAppointment';
import SelectAppointmentDate from './selectAppointmentDate/components/selectAppointmentDate/SelectAppointmentDate';
import AppointmentSuccess from './appointmentSuccess/components/appointmentSuccess/AppointmentSuccess';
import DoctorAppointmentManager from './doctorAppointmentManager/DoctorAppointmentManager';
import PatientAppointmentManager from './patientAppointmentManager/PatientAppointmentManager';
import Calendar from './home/components/Calendar';
import AppointmentDetail from './v2/pages/appointment-detail/AppointmentDetail';
import create from 'zustand';
import AppointmentDoctor from './v2/pages/appointment-detail/doctor/AppointmentDoctor';
import Loader from './v2/components/Loader';
import SelectAppointmentMotiveDoctor from './v2/pages/appointment-detail/doctor/SelectAppointmentMotiveDoctor';
import CreateAppointmentByDoctor from './v2/pages/appointment-detail/doctor/CreateAppointmentByDoctor';
import AppointmentSuccessful from './v2/pages/appointment-detail/doctor/AppointmentSuccessful';
import Layout from './Layout';
import MyDocuments from './common/components/myDocuments/MyDocuments';
import Terms from './cms/Terms';
import Privacy from './cms/Privacy';
import CompletedAppointments from './patientAppointmentManager/components/completedAppointments/CompletedAppointments';

registerLocale('es', es);
setDefaultLocale('es');

export const useInfoStore = create((set) => ({
  userInfo: null,
  setUserInfo: (data) => set({ userInfo: data })
}));

function App() {
  const { userInfo, setUserInfo } = useInfoStore((state) => state);
  const [displayNavigation, setDisplayNavigation] = useState(false);

  const { performUserLogout, checkIfLoggedIn, checkIfDoctorLoggedIn, performUserLogin, performDoctorUserLogin, getUserType, requestUserForgotPasswordRequest, requestDoctorForgotPasswordRequest } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const aCall = async () => {
      setIsLoading(true);
      const userType = getUserType();

      if (userType === '1') {
        const response = await checkIfLoggedIn();
        setUserInfo(response);
      } else if (userType === '2') {
        const response = await checkIfDoctorLoggedIn();
        setUserInfo(response);
      }

      setIsLoading(false);
    };

    aCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDisplayNavigation = (setElement = null) => {
    if (!setElement) {
      setDisplayNavigation(!displayNavigation);
    }
    if (setElement) {
      setDisplayNavigation(setElement);
    }
  };


  


  const routes = useRoutes([
    // public
    {
      path: '/',
      element: <Layout type="public" />,
      children: [
        { path: '', element: <Home authenticatedUser={userInfo} onSignOut={performUserLogout} isLoading={isLoading} user={userInfo} /> },
        { path: 'sign-in-doctor', element: <SignInDoctor onUserLogin={performDoctorUserLogin} checkIfLoggedIn={checkIfDoctorLoggedIn} /> },
        { path: 'sign-in', element: <SignIn onUserLogin={performUserLogin} checkIfLoggedIn={checkIfLoggedIn} /> },
        { path: 'sign-up', element: <SignUpPage userType="patient" /> },
        { path: 'sign-up-doctor', element: <SignUpPage userType="doctor" /> },
        { path: 'terms', element: <Terms /> },
        { path: 'privacy-policy', element: <Privacy /> },
        { path: 'who-we-are', element: <WhoWeAre /> },
        // {path : "all-appointments" , element }
        { path: 'search-results', element: <SearchResults /> },
        { path: 'about-us', element: <AboutUsPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
        { path: 'what-is-doctolink', element: <WhatIsDoctolink /> },
        { path: 'contact-us', element: <ContactUsPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
        { path: 'forgot-password', element: <ForgotPassword onRequestPasswordReset={requestUserForgotPasswordRequest} user={userInfo} /> },
        { path: 'forgot-password-doctor', element: <ForgotPasswordDoctor onRequestPasswordReset={requestDoctorForgotPasswordRequest} user={userInfo} /> },
        { path: 'complete-user-password-reset', element: <ResetPassword user={userInfo} /> },
        { path: 'complete-doctor-password-reset', element: <ResetPasswordDoctor user={userInfo} /> },
        { path: 'forgot-password-doctor', element: <ForgotPasswordDoctor onUserLogin={performDoctorUserLogin} checkIfLoggedIn={checkIfDoctorLoggedIn} /> },
        { path: 'session-expired', element: <CleanSession onSignOut={performUserLogout} user={userInfo} /> },
        { path: 'confirm-account-patient', element: <ConfirmAccountPatient /> },
        { path: 'confirm-account-doctor', element: <ConfirmAccountDoctor /> },
        { path: '*', element: <Navigate to="/" /> }
      ]
    },
    // private
    {
      path: '/',
      element: <Layout type="private" />,
      children: [
        { path: '', element: <Home authenticatedUser={userInfo} onSignOut={performUserLogout} isLoading={isLoading} user={userInfo} /> },
        { path: 'appointmentCalendar', element: <Calendar authenticatedUser={userInfo} onSignOut={performUserLogout} isLoading={isLoading} user={userInfo} /> },

        { path: 'search-results', element: userInfo?.usertype === 1 ? <SearchResults /> : <Navigate to="/" replace /> },
        { path: 'doctor-results/:doctorId/:doctorName', element: userInfo?.usertype === 1 ? <DoctorResultsTabsElement /> : <Navigate to="/" replace /> },
        { path: 'doctor-results/:doctorId/:doctorName/appointment-motive', element: userInfo?.usertype === 1 ? <SelectAppointment /> : <Navigate to="/" replace /> },
        { path: 'doctor-results/:doctorId/:doctorName/appointment-date', element: userInfo?.usertype === 1 ? <SelectAppointmentDate /> : <Navigate to="/" replace /> },
        { path: ':doctorId/:doctorName/appointment-success', element: userInfo?.usertype === 1 ? <AppointmentSuccess /> : <Navigate to="/" replace /> },

        // clean session
        { path: 'doctor-profile', element: <DoctorUserProfile user={userInfo} /> },
        { path: 'appointment-manager-doctor', element: userInfo?.usertype === 2 ? <AppointmentDoctor /> : <Navigate to="/" replace /> },
        { path: 'selected-appointment-motive-doctor', element: <SelectAppointmentMotiveDoctor /> },
        { path: 'create-appointment-by-doctor', element: userInfo?.usertype === 2 ? <CreateAppointmentByDoctor /> : <Navigate to="/" replace /> },
        { path: 'appointment-successful', element: <AppointmentSuccessful /> },
        { path: 'appointment-manager', element: <PatientAppointmentManager user={userInfo} /> },
        { path: 'user-profile', element: <DoctorUserProfile user={userInfo} /> },
        { path: 'doctor-referral', element: <h1>Referencia de médico</h1> },
        { path: 'patient-profile', element: <PatientProfilePage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
        { path: 'my-documents', element: <MyDocuments user={userInfo} /> },
        { path: 'completed-appointments', element: <CompletedAppointments user={userInfo} /> },
        { path: 'my-documents/:idPatient/:patientName', element: <MyDocuments user={userInfo} /> },
        // { path: 'my-documents/:idPatient/:patientName', element: <MyDocuments user={userInfo} /> },
        { path: '*', element: <Navigate to="/" /> },
        { path: '/doctor-appointment-date', element: <DoctorAppointmenDateSelectionPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
        { path: '/doctor-appointment-detail', element: <AppointmentDetail /> }
      ]
    },
    //other
    { path: '/side-by-side-example', element: <SideBySideImage /> },
    { path: '/search-results-2', element: <ResultsPage2 displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
    { path: '/doctor-profile-details', element: <DoctorDetailsPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
    { path: '/old-doctor-profile', element: <DoctorProfilePage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
    // { path: '/sign-up', element: <SignUpPage userType="patient" /> },
    // { path: '/sign-up-doctor', element: <SignUpPage userType="doctor" /> },
    { path: '/appointment-creation', element: <AppointmentCreationPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
    { path: '/appointment-confirmation', element: <AppointmentConfirmationPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
    { path: '/doctor-review', element: <DoctorsReviewPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
    { path: '/terms-and-conditions', element: <ContactUsPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },
    { path: '/terms-and-conditions-doctor', element: <ContactUsPage displayNavigation={displayNavigation} onUpdateDisplayNavigation={updateDisplayNavigation} /> },

    { path: '*', element: <Navigate to="/" /> }
  ]);

  if (isLoading) return <Loader />;

  return routes;
}

export default App;
