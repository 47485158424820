import { authenticatedFetch } from '../../api/request';

export default async function getDoctorAvailableDate({doctorId, startDate, endDate, setError }) {
   const onFail = () => setError('Error obteniendo fechas disponibles');

   return await authenticatedFetch(
    `/search-doctors/get-availability/doctorId/${doctorId}/startDate/${startDate}/endDate/${endDate}`, onFail
   );
}
export async function getAvailableDate({ doctorId, startDate, endDate, setError, idDoctorProfile }) {
  const onFail = () => setError('Error obteniendo fechas disponibles');

  return await authenticatedFetch(`/search-doctors/get-availabilities?idDoctorUser=${doctorId}&idDoctorProfile=${idDoctorProfile}&startDate=${startDate}&endDate=${endDate}`, onFail);
}